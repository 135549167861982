

import { useState } from "react";
import { I18n } from "i18n";
import { Button, Popconfirm } from "antd";
import { Toast } from "components";
import { DataPointsState } from "apollo/data-points";

interface NameProps {
    codeId?: string,
    active?: boolean,
    onCloseDrawer: () => void,
}

export function DataPointState({ codeId, active, onCloseDrawer }: NameProps) {
    const [isLoading, setLoading] = useState(false);

    const handleClick = async (e: any) => {
        setLoading(true);
        const { data, error, message } = await DataPointsState({ codeId });
        if (error) {
            Toast.error({ key: "error-save-datapoint", message: I18n(message, true) });
        }
        if (!error && data) {
            onCloseDrawer();
            Toast.success({
                key: "success-save-users",
                message: I18n("your_changes_were_saved_successfully", true),
            });
        }
        setLoading(false);
    };

    return (
        <>
            <Popconfirm
                disabled={isLoading}
                title={active ? `${I18n("inactive?", true)}` : `${I18n("activate?", true)}`}
                onConfirm={(e) => handleClick(e)}
                okText={I18n("yes", true)}
                cancelText={I18n("no", true)}
            >
                <Button
                    type="primary"
                    ghost
                    className="rk-button-primary"
                    disabled={isDisabled({ codeId })}
                >
                    {active ? `${I18n("inactivate", true)}` : `${I18n("activate", true)}`}
                </Button>
            </Popconfirm>
        </>
    );
}

function isDisabled(variables: any) {
    let disabled = true;
    let { codeId } = variables;
    disabled = ![codeId].every((v) => v);
    return disabled;
}