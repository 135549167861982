import { SwishSpinner } from "react-spinners-kit"; 
import './loading.less';


interface Props  {
  loading: boolean
};

export const Loading = ({ loading }: Props) => (
  <div className="loading-container">
    <SwishSpinner size={60} loading={loading} backColor="#4f5d75"  />
  </div>
);
