import { ICreateCustomers } from "./interface";

export const createCustomers: ICreateCustomers = {
  _id: "",
  name: "",
  modules: [],
  users: [],
  type: "",
  active: false,
  state: "",
  geolocation: JSON,
};
