import { Form, Switch, } from "antd";
import { I18n } from "i18n";

interface NameProps {
  id: string;
  size?: 'small' | 'default';
  disabled: boolean;
  required: boolean;
  className?: string;
  placeholder?: string;
  externalLabel?: string;
  tooltip?: string;
  onText?: string;
  isFormItem?: boolean;
  name?: string;
  value?: boolean;
  error?: string;
  offText?: string;
  label: string;
  touched?: boolean;
  default?: boolean;
  formItemStyle?: React.CSSProperties;
  style?: React.CSSProperties;
  onChange: (e: any) => void;
}


export const Toggle = (props: NameProps) => {
  let { label, offText, onText, value, disabled } = props;
  if (label) label = I18n(label, true);
  if (offText) label = I18n(offText, true);
  if (onText && value) label = I18n(onText, true);

  if (!props.isFormItem) {
    return (
      <Switch
        checked={props.value}
        disabled={disabled}
        defaultChecked={props.default}
        onChange={(value) => props.onChange(value)}
      />
    );
  }

  return (
    <Form.Item
      help={props.touched || props.error ? props.error : ""}
      validateStatus={props.touched || props.error ? "error" : "success"} label={props.externalLabel}
    >
      <Switch
        checked={props.value}
        disabled={disabled}
        defaultChecked={props.default}
        onChange={(value) => props.onChange(value)}
      />
    </Form.Item>
  );
};
