import { FC } from "react";
import "./style.less";

interface NameProps {
  text: string
}
export const Avatar: FC<NameProps> = (props) => {
  return (
    <div className="rk-avatar-container">
      <div className="rk-avatar">{props.text[0]}</div>
      <>{props.text}</>
    </div>
  );
};

