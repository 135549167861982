import { Spin as AntdSpin } from "antd";
import "./style.less";


interface NameProps {
  loading: boolean
}

export const Spin = (props: NameProps) => {
  const { loading } = props;
  return (
    <div className="spin-center">
      <AntdSpin spinning={loading} />
    </div>
  );
};
