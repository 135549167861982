// Permite letras, ñ y espacio.
export const allowLettersAndWS = RegExp(
  "^[a-zA-ZáéíóúAÁÉÍÓÚäëïöüÄËÏÖÜ\u00f1\u00d1]+(s*[a-zA-ZáéíóúAÁÉÍÓÚäëïöüÄËÏÖÜ\u00f1\u00d1]*)*[a-zA-ZáéíóúAÁÉÍÓÚäëïöüÄËÏÖÜ\u00f1\u00d1 ]*$",
);

// Permite letras, ñ, espacio, numeros.
export const allowLettersEneSpacesNumber = RegExp(
  "^[a-zA-ZáéíóúAÁÉÍÓÚäëïöüÄËÏÖÜ\u00f1\u00d1]+(s*[a-zA-ZáéíóúAÁÉÍÓÚäëïöüÄËÏÖÜ\u00f1\u00d1]*)*[a-zA-Z0-9áéíóúAÁÉÍÓÚäëïöüÄËÏÖÜ\u00f1\u00d1 ]*$",
);

// Permite letras, ñ, espacio, numeros.
export const allowLettersNumberSpecialCharacters = RegExp("^\\w+");

// Permite letras, números, ñ, punto, coma y espacio.
export const allowMessage = RegExp(
  "^[a-zA-ZÀ-ÿ\u00f1\u00d10-9.,-]+(s*[a-zA-ZÀ-ÿ\u00f1\u00d10-9]*)*[a-zA-ZÀ-ÿ\u00f1\u00d10-9.,-]*$",
);
///Permite letras y  puntos
export const allowLettersAndDots = RegExp("^[a-zA-ZÀ.,-]+(s*[a-zA-Z]*)*[a-zA-Z.,-]*$");

// Formato mail excluyendo ñ y carácteres especiales.
export const allowMail = RegExp(`^\\w+([-+.']\\w+)*@\\w+([-.]\\w+)*\\.\\w+([-.]\\w+)*$`);

// Permite letras, números, ñ, punto, coma y espacio, excluyendo carácteres especiales //
export const allowGeneral = RegExp(
  "^[a-zA-ZáéíóúAÉÍÓÚüÜ\u00f1\u00d10-9.,#]+(s*[a-zA-ZáéíóúAÉÍÓÚüÜ\u00f1\u00d10-9.,#]*)*[a-zA-ZáéíóúAÉÍÓÚüÜ\u00f1\u00d10-9.,#]*$",
);

// Permite solo números
export const allowNumbers = RegExp("^[0-9]+$");

// Permite números y letras
export const allowNumbersAndLetters = RegExp("^[A-Za-z0-9]+$");

// Permite números, punto y $ (peso chleno)
export const allowCurrency = RegExp("^[0-9.$]+$");
