import { gql } from "@apollo/client";
import sha256 from "sha256";
import client from "apollo/apollo-client";

const MUTATION_SETTOKENPASSWORD = gql`
  mutation setPassword($passhex: String!, $token: String!) {
    setPassword(passhex: $passhex, token: $token) {
      error
      data {
        _id
        name
        username
        email
        roles
        language
        verified
        active
      }
      message
    }
  }
`;

export async function setPassword(password: string, token: string | (string | null)[] | null) {
  const passwordhex = sha256(password);
  return await new Promise<{ error: boolean; data: any; message: string }>((resolve, reject) => {
    client
      .mutate({
        mutation: MUTATION_SETTOKENPASSWORD,
        variables: { passhex: passwordhex, token },
      })
      .then(
        ({
          data: {
            setPassword: { error, data, message },
          },
        }) => {
          resolve({ data, error, message });
        },
      )
      .catch((error) => {
        reject(new Error());
      });
  });
}
