import { I18n } from "i18n";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { Drawer } from "components";
import { DetailProfile } from "../components";
import "./style.less";

export function Profile({profile, setProfile}: any) {
  return (
    <Drawer
      key="drawer-profile"
      showTitle={true}
      title={I18n("profile", true)}
      placement="right"
      onClose={()=> setProfile(!profile)}
      visible={profile}
      closeIcon={[
        <div key="close-profile" style={{ display: "flex", gap: 30 }}>
          <FontAwesomeIcon icon={faTimesCircle} />
        </div>,
      ]}
    >
      <>
        <DetailProfile key="detail-profile" />
      </>
    </Drawer>
  );
}
