import { IDataPoint } from "./interface";

export const CreateDataPoint: IDataPoint = {
  name: "",
  customerId: "",
  longitude: 0,
  latitude: 0,
  module: "",
  location: "",
  active: false,
};
