import { IDataPoints } from "types";
import { useQuery, gql } from "@apollo/client";

export function GetDataPointsById(codeId: string) {
  const {
    data = { DataPointsById: {} },
    loading,
    error,
  } = useQuery(GET_DATAPOINTSBYID, {
    variables: { codeId },
  });
  return { loading, data: data.DataPointsById as IDataPoints, error };
}

const GET_DATAPOINTSBYID = gql`
  query DataPointsById($codeId: ID!) {
    DataPointsById(codeId: $codeId) {
      _id
      name
      customerId
      customer {
        _id
        name
      }
      longitude
      latitude
      active
      module
      location
    }
  }
`;
