import { useMemo } from "react";
import { Alert, Divider } from "antd";
import { GetUsers } from "apollo/users";
import { SelectList } from "components";
import { actions } from "states";
import { IDataSource } from "utils";
import { I18n } from "i18n";



const { useFormik } = actions;

export function UsersList() {
    const { data: dataUsers, loading: isLoading, error: isErrorGetUsers } = GetUsers();
    const { values: { customer: { createCustomers } }, onChange } = useFormik();

    const selectUsers = useMemo(() => {
        let memoUsers: IDataSource[] = []
        dataUsers?.forEach((data: any) => {
            if (createCustomers.users && !createCustomers.users.includes(data._id)) return;
            memoUsers.push({ key: data._id, value: data._id, label: I18n(data.name, true) });
        });
        return memoUsers;
    }, [createCustomers.users, dataUsers]);

    const onDelete = (value: string) => {
        const filtredData = createCustomers.users.filter((key: any) => key !== value);
        onChange(`customer.createCustomers.users`, filtredData, true);
    };

    if (isErrorGetUsers) return <Alert message={I18n("internal_server_error", true)} type="error" />

    return (
        <>
            {createCustomers.users && createCustomers.users.length > 0 && <>
                <Divider />
                <SelectList
                    loading={isLoading}
                    onDelete={onDelete}
                    key="list-select-users"
                    data={selectUsers}
                />
            </>}
        </>
    )
}