import { useQuery, gql } from "@apollo/client";
import { User } from "types";
const GET_USERS = gql`
  query Users($type: String) {
    Users(type: $type) {
      _id
      name
      username
      email
      roles
      language
      verified
      active
      type
    }
  }
`;

export function GetUsersByType(type: string) {
  const { data = {}, loading, refetch, error } = useQuery(GET_USERS, { variables: { type } });
  return { loading, data: data.Users as User[], refetch, error };
}
