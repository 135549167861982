import { DataPoint } from "types";
import { gql } from "@apollo/client";
import client from "apollo/apollo-client";

export async function DataPointsConfigure(variables: DataPoint) {
  return await new Promise<{ loading: boolean; error: boolean; data: any; message: string }>(
    (resolve, reject) => {
      client
        .mutate({
          mutation: CONFIGURE_DATA_POINTS,
          variables,
          refetchQueries: ["DataPoint", "DataPointsById"],
        })
        .then(
          ({
            data: {
              DataPointsConfigure: { error, data, message },
            },
            loading,
          }: any) => {
            resolve({ data, loading, error, message });
          },
        )
        .catch((error) => {
          reject(new Error("_server_error_"));
        });
    },
  );
}

const CONFIGURE_DATA_POINTS = gql`
  mutation DataPointsConfigure(
    $codeId: ID
    $name: String!
    $longitude: Float!
    $latitude: Float!
    $module: String!
    $location: String!
  ) {
    DataPointsConfigure(
      codeId: $codeId
      name: $name
      longitude: $longitude
      latitude: $latitude
      module: $module
      location: $location
    ) {
      error
      data {
        _id
        name
        customerId
        longitude
        latitude
        module
        location
      }
      message
    }
  }
`;
