
import { useSelector, RootStateOrAny } from "react-redux";
import { useLocation } from "react-router-dom";
import queryString from "query-string";
import { Login } from "./login";
import { ResetPassword } from "./reset-password";
import { SetPassword } from "./set-password";

export const Account = () => {
    const location = useLocation();
    const { token } = queryString.parse(location.search);
    const { resetPassword } = useSelector(({ account }: RootStateOrAny) => account);
    let view: React.ReactNode = <Login />;
    if (resetPassword) view = <ResetPassword />;
    if (token) view = <SetPassword token={token} />;
    return <>{view}</>;
};
