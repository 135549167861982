import { IModules } from "./interface";

interface IDefaultState {
  data: IModules[];
}

const defaultState: IDefaultState = {
  data: [],
};

export const modules = (state = defaultState, action: any) => {
  switch (action.type) {
    case "modules.data":
      return Object.assign({}, state, { data: action.data });
    case "modules.reset":
      return Object.assign({}, state, { data: [] });
    default:
      return state;
  }
};
