import { useState } from "react";
import { Layout } from "antd";
import { Redirect, useLocation } from "react-router-dom";
import { actions } from "states";
import { Sider, Header } from "../components";

import { RoutesCustomers } from "./customers";
import { RoutesUsers } from "./users";
import { RoutesDataPoints } from "./data-points";
import { RoutesDevices } from "./devices";
import { Profile } from "../../profile";

const { Content } = Layout;
const { useHeader } = actions;

export function AppLayout() {
    const [profile, setProfile] = useState<boolean>(false);
    const { pathname } = useLocation();
    const location = pathname.split("/");
    const { collapseNav } = useHeader();
    if (!location[1]) return <Redirect to="/dashboard" />
    return (
        <Layout >
            {!collapseNav && <Sider />}
            <Layout style={{ minHeight: "100vh", background: "#dde2ed" }}>
                <Header setProfile={setProfile} />
                <Content className="rk-layout-content">
                    <RoutesCustomers />
                    <RoutesUsers />
                    <RoutesDataPoints />
                    <RoutesDevices />
                </Content>
            </Layout>
            <Profile profile={profile} setProfile={setProfile} />
        </Layout >

    );
}
