import { I18n } from "i18n";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { Table as TableUI, Tag } from "antd";
import { DataPoint } from "types";
import { GetDataPoints } from "apollo/data-points";

const { Column } = TableUI;

interface NameProps {
    onShowDrawer: (_id?: string) => void
}

export function ListDataPoints({ onShowDrawer }: NameProps) {
    const history = useHistory();
    const { data: dataDataPoints, loading: isLoading, error } = GetDataPoints();
    if (error) history.push("/error-server");

    return (<TableUI
        dataSource={dataDataPoints}
        pagination={false}
        loading={isLoading}
        bordered={true}
        rowKey={(data) => data._id}
        onRow={(data: DataPoint, rowIndex) => {
            return {
                onClick: () => onShowDrawer(data?._id),
            };
        }}
    >
        {["name", "longitude", "latitude"].map((field) => (
            <Column title={I18n(field, true)} dataIndex={field} key={field} />
        ))}
        <Column
            title={I18n("customer", true)}
            dataIndex={"customer"}
            key={I18n("customer", true)}
            render={(key) => key.name}
        />
        <Column
            title={I18n("modules", true)}
            dataIndex={"module"}
            key={I18n("module", true)}
            render={(key) => (key ? I18n(key, true) : null)}
        />
        <Column
            title={I18n("location", true)}
            dataIndex={"location"}
            key={I18n("location", true)}
            render={(key) => (key ? I18n(key, true) : I18n("no_location"))}
        />
        <Column
            title={I18n("state", true)}
            dataIndex={"active"}
            key={"active"}
            render={(value) => {
                return (
                    <>
                        {value && value === true ? (
                            <Tag icon={<FontAwesomeIcon icon={faCheck} />} color={"success"}>
                                {`  ${I18n("active", true)}`}
                            </Tag>
                        ) : (
                            <Tag icon={<FontAwesomeIcon icon={faTimes} />} color={"error"}>
                                {`  ${I18n("inactive", true)}`}
                            </Tag>
                        )}
                    </>
                );
            }}
        />
        <Column
            key="action"
            render={() => [
                <span style={{ cursor: "pointer" }}>
                    <FontAwesomeIcon icon={faEllipsisH} />
                </span>,
            ]}
        />
    </TableUI>
    );
}
