import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunk from "redux-thunk";
import { reducer } from "./states";

declare global {
  interface Window {
    __REDUX_DEVTOOLS_EXTENSION_COMPOSE___?: typeof compose;
  }
}

const reducers: any = combineReducers({ ...reducer });
const composeEnhancers =
  typeof window === "object" && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE___
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE___()
    : compose;
const enhancer = composeEnhancers(applyMiddleware(thunk));

export default createStore(reducers, enhancer);
