import { useMemo } from "react";
import { Typography, Divider } from "antd";
import { FormCard, Input, Space, Select, Toggle } from "components";

import { actions } from "states";
import { I18n } from "i18n";
import { Customer } from "types";

interface NameProps {
    disabled: boolean
    datCustomerById: Customer
}

const { useFormik } = actions;

export function InfoGeneral({ disabled, datCustomerById }: NameProps) {
    const { values: { customer: { createCustomers } }, onChange } = useFormik();

    const TYPE = useMemo(() => {
        return [
            { value: "test", label: I18n("test", true), text: I18n("test", true), },
            { value: "pilot", label: I18n("pilot", true), text: I18n("pilot", true), },
            { value: "client", label: I18n("client", true), text: I18n("client", true) }
        ];
    }, []);


    return (
        <>
            <FormCard
                disabled={disabled}
                title={I18n("info_general")}
                description={I18n("info_basic_customer")} >
                <Space.Columns>
                    <Input
                        value={createCustomers.name}
                        size="middle"
                        type="text"
                        placeholder={`${I18n("name", true)}:*`}
                        onChange={(e) => onChange(`customer.createCustomers.${e.target.name}`, e.target.value, true)}
                        externalLabel={`${I18n("name", true)}:*`}
                        required={true}
                        disabled={false}
                        id="name"
                        name="name"
                    />
                </Space.Columns>
                <Space.Columns>
                    <Select
                        id="type"
                        value={createCustomers.type}
                        defaultValue={datCustomerById.type}
                        externalLabel={I18n("type", true)}
                        style={{ width: "100%" }}
                        label={`${I18n("type", true)}:*`}
                        onChange={(value) => onChange(`customer.createCustomers.type`, value, true)}
                        data={TYPE}
                        placeholder={`${I18n("type", true)}:*`}
                    />
                    <Toggle
                        isFormItem={true}
                        required={true}
                        disabled={false}
                        id="active"
                        default={datCustomerById.active}
                        value={createCustomers.active}
                        externalLabel={I18n("state", true)}
                        style={{ width: "100%" }}
                        label={`${I18n("state", true)}:*`}
                        onChange={(value) => onChange(`customer.createCustomers.active`, value, true)}
                    />
                </Space.Columns>
                <Divider />
                <Typography.Title level={5}>{I18n("geolocation", true)}</Typography.Title>
                <Space.Columns>
                    <Input
                        size="middle"
                        type="number"
                        value={createCustomers.geolocation?.latitude}
                        placeholder={I18n("latitude", true)}
                        onChange={(e) => onChange(`customer.createCustomers.geolocation.${e.target.name}`, e.target.value, true)}
                        externalLabel={I18n("latitude", true)}
                        required={true}
                        disabled={false}
                        id="latitude"
                        name="latitude"
                    />
                    <Input
                        size="middle"
                        type="number"
                        value={createCustomers.geolocation?.longitude}
                        placeholder={I18n("longitude", true)}
                        onChange={(e) => onChange(`customer.createCustomers.geolocation.${e.target.name}`, e.target.value, true)}
                        externalLabel={I18n("longitude", true)}
                        required={true}
                        disabled={false}
                        id="longitude"
                        name="longitude"
                    />
                </Space.Columns>
            </FormCard>
        </>
    )
}