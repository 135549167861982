import { gql } from "@apollo/client";
import client from "apollo/apollo-client";

interface ParamsSate {
  codeId?: string;
}

export async function DataPointsState(variables: ParamsSate) {
  return await new Promise<{ loading: boolean; error: boolean; data: any; message: string }>(
    (resolve, reject) => {
      client
        .mutate({
          mutation: STATE_DATA_POINTS,
          variables,
          refetchQueries: ["DataPoint", "DataPointsById"],
          fetchPolicy: "network-only",
        })
        .then(
          ({
            data: {
              DataPointsState: { error, data, message },
            },
            loading,
          }: any) => {
            resolve({ data, loading, error, message });
          },
        )
        .catch((error) => {
          reject(new Error("_server_error_"));
        });
    },
  );
}

const STATE_DATA_POINTS = gql`
  mutation DataPointsState($codeId: ID!) {
    DataPointsState(codeId: $codeId) {
      error
      data {
        _id
        name
        customerId
        longitude
        latitude
        active
        module
        location
      }
      message
    }
  }
`;
