import { useQuery, gql } from "@apollo/client";

const GET_CUSTOMERS = gql`
  query Customers {
    Customers {
      _id
      name
      createdAt
      weekStart
      localidad
      geolocation
      modules
      modulesNames
      usersName
      users
      time
      thresholds
      active
      state
      type
    }
  }
`;

export function useCustomers() {
  const { data = {}, loading, refetch, error } = useQuery(GET_CUSTOMERS);
  return { loading, data: data.Customers || [], refetch, error };
}
