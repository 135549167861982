import { Route, Switch } from "react-router-dom";
import { Users } from "../../users/views/users";
import { CreateUser } from "../../users/views/user-create";

export function RoutesUsers() {
    return (
        <Switch>
            <Route
                exact
                path="/users"
                component={Users}
            />
            <Route
                exact
                path="/users/create"
                component={CreateUser}
            />
            <Route
                exact
                path="/users/:id"
                component={CreateUser}
            />
        </Switch>
    );
}