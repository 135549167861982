import { useQuery, gql } from "@apollo/client";

export function GetCustomersById(_id: string) {
  const {
    data = { UserById: {} },
    loading: isLoading,
    error,
  } = useQuery(GET_CUSTOMERBYID, { variables: { _id }, skip: skip({ _id }) });
  return { isLoading, data: data.CustomersById || [], error };
}

function skip(variables: any) {
  let skip = true;
  let { _id } = variables;
  skip = ![_id].every((v) => v);
  return skip;
}

export const GET_CUSTOMERBYID = gql`
  query CustomersById($_id: ID!) {
    CustomersById(_id: $_id) {
      _id
      name
      createdAt
      weekStart
      localidad
      geolocation
      modules {
        _id
        name
      }
      users {
        _id
        name
      }
      time
      thresholds
      active
      state
      type
    }
  }
`;
