import { Table as TableUI, Tag } from "antd";
import { I18n } from "i18n";
import { Toast } from "components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faTimes, faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { Devices } from "types";
import { GetDevices } from "apollo/devices";

const { Column } = TableUI;
interface NameProps {
    onShowDrawer: (_id?: string) => void
}

function findFilters (data:any, key:any) {
    let out = data.map((item:any) => item[key].name);
    let find = out.reduce((memo:any, key:any) => {
        if (memo.includes(key)) return memo;
        memo.push(key);
        return memo; 
      }, []);
    return find.map((value:any) => ({text: I18n(value, true), value}))
  } 
// function findFilters(data: any[], key: any) {
//     console.log(key, data );
//      let out = data.map((item:any) => item[key].name);
//      console.log(out);
//     return  [] //out.map((value: any) => ({key:value, text:value, value }));
//   } 

export function ListDevices({ onShowDrawer }: NameProps) {
    const { data: dataDevices, loading: isLoading, error } = GetDevices();
    if (error) {
        Toast.error({ key: "error-list-devices", message: I18n("_server_error_", true) });
    }

    return (<TableUI
        dataSource={dataDevices}
        pagination={false}
        loading={isLoading}
        bordered={true}
        rowKey={(data) => data._id}
        onRow={(data: Devices, rowIndex) => {
            return {
                onClick: () => onShowDrawer(data?._id),
            };
        }}
    >
        {["name"].map((field) => (
            <Column
                title={I18n(field, true)}
                dataIndex={field}
                key={field}
                render={(key) => I18n(key, true)}
            />
        ))}
        <Column title={I18n("code", true)} dataIndex={"code"} key={I18n("code", true)} />
        <Column
            title={I18n("data_points_name", true)}
            dataIndex={"data_point"}
            key={I18n("data_point", true)}
            render={(key) => key.name}
            filters={findFilters(dataDevices || [], `data_point`)}
            onFilter={(value, record:any) => record.data_point.name === value}
        />
        <Column
            title={I18n("customer", true)}
            dataIndex={"customer"}
            key={I18n("customer", true)}
            render={(key) => key.name}
            filters={findFilters(dataDevices || [], `customer`)}
            onFilter={(value, record:any) => record.customer.name === value}
        />
        <Column
            title={I18n("type", true)}
            dataIndex={"type"}
            key={I18n("type", true)}
            render={(key) => I18n(key, true)}
            
        />
        <Column
            title={I18n("state", true)}
            dataIndex={"active"}
            key={"active"}
            render={(value) => {
                return (
                    <>
                        {value && value === true ? (
                            <Tag icon={<FontAwesomeIcon icon={faCheck} />} color={"success"}>
                                {`  ${I18n("active", true)}`}
                            </Tag>
                        ) : (
                            <Tag icon={<FontAwesomeIcon icon={faTimes} />} color={"error"}>
                                {`  ${I18n("inactive", true)}`}
                            </Tag>
                        )}
                    </>
                );
            }}
        />
        <Column
            width={80}
            title={I18n("color", true)}
            dataIndex={"color"}
            key={"color"}
            render={(color) => <div style={{ width: 15, height: 15, background: color }} />}
        />
        <Column
            key="action"
            width={50}
            render={() => [
                <span style={{ cursor: "pointer" }}>
                    <FontAwesomeIcon icon={faEllipsisH} />
                </span>,
            ]}
        />
    </TableUI>
    );
}
