import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { PageContent, Drawer } from "components";
import { I18n } from "i18n";
import { ListDevices } from "../components";

import { DeviceDetail } from "./device-detail";
export function Devices() {
    const [visible, setVisible] = useState<boolean>(false)
    const [codeId, setCodeId] = useState<string>("")

    const onCloseDrawer = () => {
        setCodeId("");
        setVisible(false);
    };

    const onShowDrawer = (value?: string) => {
        if (!value) return setVisible(true);
        setCodeId(value);
        setVisible(true);
    };

    return (
        <PageContent
            title={I18n("list_devices", true, true)}
            backIcon={false}
        >
            <ListDevices onShowDrawer={onShowDrawer} />
            <Drawer
                showTitle={true}
                title={I18n("information_device", true, true)}
                placement="right"
                onClose={() => onCloseDrawer()}
                visible={visible}
                closeIcon={[
                    <div style={{ display: "flex", gap: 30 }}>
                        <FontAwesomeIcon icon={faTimesCircle} />
                    </div>,
                ]
                }
            >
                <DeviceDetail codeId={codeId} onCloseDrawer={onCloseDrawer} />

            </Drawer >
        </PageContent >
    )
}