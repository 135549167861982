import { useQuery, gql } from "@apollo/client";
import { User } from "types";
const GET_USERS = gql`
  query Users {
    Users {
      _id
      name
      username
      email
      roles
      language
      verified
      active
    }
  }
`;

export function GetUsers() {
  const { data = {}, loading, refetch, error } = useQuery(GET_USERS);
  return { loading, data: data.Users as User[], refetch, error };
}
