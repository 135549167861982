import { List as ListAntd, Form, Avatar } from "antd";
import { IDataSource } from "utils";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleXmark } from "@fortawesome/free-solid-svg-icons";
import "./style.less";


interface NameProps {
    data: IDataSource[] | any;
    onDelete: (value: any) => void;
    content?: JSX.Element[];
    selectedValue?: string;
    id?: string;
    formGroupLabel?: string;
    name?: string;
    loading?: boolean;
    className?: string;
    required?: boolean;
    tooltip?: string;
    error?: string;
}

export const SelectList = (props: NameProps) => {

    if (!props.formGroupLabel) {
        return <ListAntd
            loading={props.loading}
            itemLayout="horizontal"
            dataSource={props.data}
            renderItem={(data: IDataSource) => (
                <ListAntd.Item actions={[<div key="actions-delete" onClick={() => props.onDelete(data.value)}>
                    <FontAwesomeIcon key="actions-fa-circle-xmark" icon={faCircleXmark} />
                </div>]} >
                    <ListAntd.Item.Meta key={data.value} avatar={<Avatar shape="square" size={"small"} icon={data.label[0] || data.value[0]}></Avatar>} className="rk-list-item-meta"
                        title={data.label || data.value}
                    />
                    {props.content}
                </ListAntd.Item>
            )}
        />
    }

    return (
        <Form.Item
            name={props.name}
            labelCol={{ span: 24 }}
            rules={[{ required: props.required, message: props.error }]}
            tooltip={props.tooltip}
            label={props.formGroupLabel}
        >
            <ListAntd
                loading={props.loading}
                itemLayout="horizontal"
                dataSource={props.data}
                renderItem={(data: IDataSource, i: number) => (
                    (i < 3 && <ListAntd.Item actions={[<div key="actions-delete" onClick={() => props.onDelete(data.value)}>
                        <FontAwesomeIcon icon={faCircleXmark} />
                    </div>]} >
                        <ListAntd.Item.Meta key={data.value} avatar={<Avatar size="default" icon={data.label[0] || data.value[0]}></Avatar>} className="rk-list-item-meta"
                            title={data.label || data.value}
                        >
                            {props.content}
                        </ListAntd.Item.Meta>

                    </ListAntd.Item>)
                )}
            />
        </Form.Item>
    );
};