import { useDispatch, useSelector, RootStateOrAny } from "react-redux";

export function useHeader() {
  const dispatch = useDispatch();
  const { collapseNav } = useSelector(({ header }: RootStateOrAny) => header);

  const onCollapseNav = (e: any) => {
    e.preventDefault();
    dispatch({ type: "header.collapsedNav", collapse: !collapseNav });
  };
  return {
    onCollapseNav,
    collapseNav,
  };
}
