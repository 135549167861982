import { useApolloClient, gql } from "@apollo/client";
import sha256 from "sha256";
import { useSelector, useDispatch, RootStateOrAny } from "react-redux";
import { useHistory } from "react-router-dom";

const MUTATION_LOGIN = gql`
  mutation login($email: String!, $passwordhex: String!) {
    login(email: $email, passwordhex: $passwordhex) {
      error
      data {
        _id
        name
        email
        roles
        language
        active
      }
      message
    }
  }
`;

export function useAuth() {
  const { account } = useSelector(({ formik }: RootStateOrAny) => formik);
  const { email, password } = account;
  let history = useHistory();
  const dispatch = useDispatch();
  const client = useApolloClient();

  const signIn = async () => {
    dispatch({ type: "account.loading", loading: true });
    const passwordhex = sha256(password);
    const {
      data: {
        login: { error, data, message },
      },
    } = await client.mutate({
      mutation: MUTATION_LOGIN,
      variables: { email, passwordhex },
    });

    if (error) dispatch({ type: "account.error", message: message });
    if (data) {
      dispatch({ type: "auth.data", user: data });
      localStorage.setItem("auth:userid", data._id);
    }
    window.location.reload();
    dispatch({ type: "account.loading", loading: false });
  };

  const signOut = () => {
    localStorage.clear();
    dispatch({ type: "auth.reset" });
    client.resetStore();
    window.location.reload();
    history.push("/");
  };

  const isDisabled = () => {
    return account["email"] && account["password"] ? false : true;
  };

  return {
    signIn,
    signOut,
    isDisabled,
  };
}
