import { Spin } from "components";
import { Descriptions, Alert, Button } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { GetDeviceById } from "apollo/devices";
import { I18n } from "i18n";

interface NameProps {
    onCloseDrawer: () => void,
    codeId: string
}
export function DeviceDetail({ codeId, onCloseDrawer }: NameProps) {
    const { data: dataDeviceById, loading: isLoading, error } = GetDeviceById(codeId);

    // This is the function we wrote earlier
    async function copyTextToClipboard(text: string) {
        if ("clipboard" in navigator) {
            return await navigator.clipboard.writeText(text);
        } else {
            return document.execCommand("copy", true, text);
        }
    }

    const handleCopyClick = () => {
        copyTextToClipboard(dataDeviceById.code);
    };

    if (codeId && error) return <Alert message={I18n("internal_server_error", true)} type="error" />;
    if (isLoading) return <Spin loading={isLoading} />;

    return (
        <>
            <Descriptions size="middle" column={1}>
                <Descriptions.Item label={`${I18n("name", true)} `}>
                    {dataDeviceById?.name}
                </Descriptions.Item>
                <Descriptions.Item className="rk-descriptions-items" label={`${I18n("code", true)}`}>
                    {dataDeviceById?.code}
                    <Button
                        style={{ marginLeft: "auto" }}
                        icon={<FontAwesomeIcon icon={faCopy} />}
                        size="large"
                        onClick={handleCopyClick}
                    />
                </Descriptions.Item>
                <Descriptions.Item label={`${I18n("data_points_name", true)}`}>
                    {dataDeviceById?.data_point?.name}
                </Descriptions.Item>
                <Descriptions.Item label={`${I18n("type", true)}`}>
                    {I18n(dataDeviceById?.type, true)}
                </Descriptions.Item>
                <Descriptions.Item label={`${I18n("color", true)}`}>
                    <div style={{ width: 15, height: 15, background: dataDeviceById?.color }} />
                </Descriptions.Item>
            </Descriptions>
            <div className="rk-button-group">
                {/* <DataPointState
                    active={dataDataPointById.active}
                    codeId={dataDataPointById._id}
                    onCloseDrawer={onCloseDrawer}
                /> */}
            </div>
        </>
    );
}