import React from "react";
import { Select as SelectAntd, Form } from "antd";

const { Option, OptGroup } = SelectAntd;

interface NameProps {
  id: string;
  data: any[];
  label: string;
  allowClear?: boolean;
  showSearch?: boolean;
  altOptions?: boolean;
  disabled?: boolean;
  className?: string;
  loading?: boolean;
  optGroups: string[];
  keyOptions?: boolean;
  value?: string;
  notables?: any[];
  defaultValue?: string;
  style?: React.CSSProperties;
  all?: boolean;
  placeholder?: string;
  externalLabel?: string;
  isFormItem?: boolean;
  error?: string;
  touched?: boolean;
  onChange: (e: any) => void;
}

export const SelectMultipleOptGroup = (props: NameProps) => {
  let {
    data = [],
    value,
    notables = [],
    showSearch,
    allowClear,
    loading,
    label,
    disabled,
    isFormItem,
    error,
    touched,
    externalLabel,
    optGroups
  } = props;
  let options: React.ReactNode[] = [
    <Option value={label} key="EMPTY">
      {label}
    </Option>,
  ];
  let alt_options: React.ReactNode[] = [];
  let catOptions: any[] = [];
  let ui_options: React.ReactNode[] = [];

  if (notables && notables.length)
    notables = notables.map((note) => note.value);

  if (data.length) {
    optGroups.forEach((optGroup) => {
      options = [];
      data.forEach((d: any, i: any) => {
        if (!d.value) return null;
        if (d.OptGroup === optGroup) {
          let style = {};
          if (props.altOptions && !notables.includes(d.name || d.value)) {
            alt_options.push(
              <Option style={style} value={d.value} key={d.name || d.value}>
                {d.name || d.value}
              </Option>
            );
          } else {
            options.push(
              <Option style={style} value={d.value} key={d.name || d.value}>
                {d.name || d.value}
              </Option>
            );
          }
        };
      });

      ui_options.push(
        <OptGroup key={optGroup} label={optGroup}>
          {options}
        </OptGroup>
      );
    });


    if (props.all) {
      options.unshift(
        <Option value={"__ALL__"} disabled={true} key={-1}>
          ------
        </Option>
      );
      options.unshift(
        <Option value={"__ALL__"} key={0}>
          {"select_all"}
        </Option>
      );
    }
  }

  if (isFormItem) {
    return (
      <SelectAntd
        style={{ width: '100%', ...props.style }}
        defaultValue={value}
        value={value}
        allowClear={allowClear}
        placeholder={label}
        showSearch={showSearch}
        loading={loading}
        maxTagCount='responsive'
        mode="multiple"
        filterOption={(input: any, option: any) => {
          return (
            option.props.children
              .toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          );
        }}
        onChange={(e: any) => props.onChange(e)}
        disabled={disabled || catOptions.length < 2}
      >
        {ui_options}
      </SelectAntd>
    );
  }

  return (
    <Form.Item help={touched || error ? error : ""}
      validateStatus={touched || error ? "error" : "success"} label={externalLabel}>
      <SelectAntd
        style={{ width: '100%', ...props.style }}
        defaultValue={value}
        value={value}
        allowClear={allowClear}
        placeholder={label}
        showSearch={showSearch}
        loading={loading}
        maxTagCount='responsive'
        mode="multiple"
        filterOption={(input: any, option: any) => {
          return (
            option.props.children
              .toString()
              .toLowerCase()
              .indexOf(input.toLowerCase()) >= 0
          );
        }}
        onChange={(e: any) => props.onChange(e)}
        disabled={disabled}
      >
        {ui_options}
      </SelectAntd>
    </Form.Item>
  );
};
