import { IHeader, IAction } from "./interface";

const defaultState: IHeader = {
  collapseNav: false,
};

export const header = (state = defaultState, action: IAction) => {
  switch (action.type) {
    case "header.collapsedNav":
      return Object.assign({}, state, { collapseNav: action.collapse });
    default:
      return state;
  }
};
