
import { FC } from "react";
import classnames from "classnames";
import "./style.less";


interface PropsType {
    hide?: boolean
}

const Layout: FC<PropsType> = (props) => {
    return <div className="rk-space-layout">
        {props.children}
    </div>
}


const Columns: FC<PropsType> = (props) => {
    return <div className="rk-space-columms">
        {props.children}
    </div>
}


const Wrap: FC<PropsType> = (props) => {
    return <div className={`rk-space-wrap ${classnames({ hide: props.hide })}`}>
        {props.children}
    </div>
}

export const Space = { Layout, Columns, Wrap }