import { useEffect, useState } from "react";
import { useApolloClient, gql } from "@apollo/client";
import { useDispatch } from "react-redux";

const Current = gql`
  query Current {
    ProfileUser {
      _id
      name
      email
      roles
      language
      active
    }
    Modules {
      _id
      name
      color
      active
      type
      application
    }
    Roles {
      _id
      name
      active
      access
    }
  }
`;

export function useParams() {
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const client = useApolloClient();

  useEffect(() => {
    const fetchQuery = async () => {
      setLoading(true);
      const {
        data: { ProfileUser, Modules, Roles },
      } = await client.query({ query: Current });

      if (ProfileUser && ProfileUser._id !== null) {
        dispatch({ type: "profile.data", user: ProfileUser });
      }
      if (Modules && Modules.length) {
        dispatch({ type: "modules.data", data: Modules });
      }
      if (Roles && Roles.length) {
        dispatch({
          type: "roles.data",
          roles: Roles,
        });
      }

      setLoading(false);
    };
    fetchQuery();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return loading;
}
