import { useState } from "react";
import { I18n } from "i18n";
import { Button } from "antd";
import { Toast } from "components";
import { useHistory } from "react-router-dom";
import { actions } from "states";
import { SaveCustomers } from "apollo/customers";

interface NameProps {
    customersId: string;
    disabled: boolean;
    onClick: () => void;
}

const { useFormik } = actions;

export function ButtonSave({ customersId }: NameProps) {
    const history = useHistory();
    const [isLoading, setLoading] = useState(false);
    const { values: { customer: { createCustomers } } } = useFormik();

    const handleClick = async () => {
        setLoading(true);
        const { data, error, message } = await SaveCustomers({ ...createCustomers });
        if (error) {
            Toast.error({ key: "error-save-users", message: I18n(message, true) });
        }
        if (!error && data) {
            history.push("/farms");
            Toast.success({
                key: "success-save-users",
                message: I18n("your_changes_were_saved_successfully", true),
            });
        }
        setLoading(false);
    };

    return (
        <Button
            loading={isLoading}

            type="primary"
            className="rk-button-primary"
            disabled={isLoading}
            onClick={handleClick}
        >
            {I18n("save", true)}
        </Button>
    )
}