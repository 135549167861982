import React from 'react';
import { ApolloProvider } from "@apollo/client/react";
import { Provider } from "react-redux";
import ReactDOM from 'react-dom';
import reportWebVitals from './reportWebVitals';
import client from "./apollo/apollo-client";
import store from "./store";
import { App } from "./pages";
import "./index.less";

function Site() {
  return (
    <ApolloProvider client={client}>
      <Provider store={store}>
        <App />
      </Provider>
    </ApolloProvider>
  );
}


ReactDOM.render(
  <React.StrictMode>
    <Site />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
