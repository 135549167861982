import { useQuery, gql } from "@apollo/client";
import { Devices } from "types";

export function GetDeviceById(codeId: string) {
  const {
    data = {},
    loading,
    error,
  } = useQuery(GET_DEBVICESBYID, {
    variables: { DeviceById: codeId },
  });
  return { loading, data: data.DeviceById as Devices, error };
}

const GET_DEBVICESBYID = gql`
  query DeviceById($DeviceById: ID!) {
    DeviceById(DeviceById: $DeviceById) {
      _id
      name
      code
      type
      customerId
      location
      customer {
        _id
        name
      }
      color
      data_point {
        _id
        name
      }
      active
    }
  }
`;
