import { useState, useEffect } from "react";
import moment from "moment";
import { useHistory } from "react-router-dom"
import { useApolloClient } from "@apollo/client";
import { Layout, Avatar, Menu } from "antd";
import { useDispatch, useSelector, RootStateOrAny} from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSignOutAlt, faOutdent, faUser } from "@fortawesome/free-solid-svg-icons";
import { I18n } from "i18n";
import { actions } from "states";
import "./style.less";


const { useHeader } = actions;
export function Header({setProfile}:any) {
    const history = useHistory();
    const dispatch = useDispatch();
    const { name} = useSelector(({ profile }:RootStateOrAny ) => profile.data);
    const [date, setDate] = useState(new Date());
    const [sun, setSun] = useState(true);
    const [datetime, setDatetime] = useState("");
    const { onCollapseNav } = useHeader();
    const client = useApolloClient();
    useEffect(() => {
        const timer = setInterval(() => setDate(new Date()), 30000);
        return () => {
            clearInterval(timer);
        };
    }, []);
    useEffect(() => {
        const sun = date.getHours() > 6 && date.getHours() < 18;
        let dateformat = moment(date).format("LLLL");
        const currentDate = `${I18n(`current_datetime`, true)} ${dateformat}`;
        setDatetime(currentDate);
        setSun(sun);
    }, [date]);

    const signOut = () => {
        localStorage.clear();
        dispatch({ type: "auth.reset" });
        client.resetStore();
        window.location.reload();
        history.push("/");
    };

    return <Layout.Header className="header">
        <div className="header-container">
            <div className="hamburger" onClick={(e) => onCollapseNav(e)}>
                <FontAwesomeIcon icon={faOutdent} />
            </div>
            <div className="profile-container">
                <Avatar className="profile-avatar">
                    <span>{name[0]}</span>
                </Avatar>
                <div className="profile-message">
                    <h2>{`${I18n("hi", true)} ${name}`}</h2>
                    <h3>{`${datetime} ${sun ? "☀️" : "🌙"}`}</h3>
                </div>
            </div>

        </div>
        <Menu className="rk-menu" mode="horizontal" defaultSelectedKeys={[]} style={{ lineHeight: "60px", backgroundColor: "transparent" }}>
            <Menu.Item className="rk-menu-item" key="user-profile"  onClick={setProfile} style={{ float: 'right' }}>
                <FontAwesomeIcon icon={faUser} />
            </Menu.Item>
            <Menu.Item className="rk-menu-item" key="logout" style={{ float: 'right' }} onClick={signOut}>
                <FontAwesomeIcon icon={faSignOutAlt} />
            </Menu.Item>
        </Menu>
    </Layout.Header>;
}
