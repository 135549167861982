import { IApplication } from "./interface";

const defaultState: IApplication = {
  name: "",
  color: "",
  active: false,
  type: "",
  applicationId: "",
};

export const application = (state = defaultState, action: any) => {
  switch (action.type) {
    case "application.data":
      return Object.assign({}, state, { data: action.user });
    case "application.reset":
      return Object.assign({}, state, { data: {} });
    default:
      return state;
  }
};
