import { message } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import "./style.less";

interface PropsType {
  key: string;
  message: string | React.ReactNode;
  duration?: number;
}

export const Toast = {
  error: (props: PropsType) =>
    message.error({
      key: props.key,
      content: props.message,
      duration: props.duration,
      icon: (
        <div onClick={() => message.destroy(props.key)}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      ),
      className: `${props.key}-message-error`,
    }),
  success: (props: PropsType) =>
    message.success({
      key: props.key,
      content: props.message,
      duration: props.duration,
      icon: (
        <div onClick={() => message.destroy(props.key)}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      ),
      className: `${props.key}-message-success`,
    }),
  warning: (props: PropsType) =>
    message.warning({
      key: props.key,
      content: props.message,
      duration: props.duration,
      icon: (
        <div onClick={() => message.destroy(props.key)}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      ),
      className: `${props.key}-message-warning`,
    }),
};
