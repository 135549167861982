import { gql } from "@apollo/client";
import client from "apollo/apollo-client";

export async function DeviceState(codeId: string) {
  return await new Promise((resolve, reject) => {
    client
      .mutate({
        mutation: STATE_DEVICES,
        variables: { codeId },
        refetchQueries: ["DevicesByCustomer", "DeviceById"],
        fetchPolicy: "network-only",
      })
      .then(
        ({
          data: {
            DeviceState: { error, data, message },
          },
          loading,
        }: any) => {
          resolve({ data, loading, error, message });
        },
      )
      .catch((error) => {
        reject(new Error("_server_error_"));
      });
  });
}

const STATE_DEVICES = gql`
  mutation DeviceState($codeId: ID!) {
    DeviceState(codeId: $codeId) {
      error
      data {
        _id
        name
        code
        type
        location
        color
        data_point
        data_points_name
        active
      }
      message
    }
  }
`;
