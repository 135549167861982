import { Input, Loading, Toast } from "components";
import { Form, Button } from "antd";
import { useHistory } from "react-router-dom";
import { setPassword } from "apollo/account";
import { useSelector, RootStateOrAny, useDispatch } from "react-redux";
import { actions } from "states";
import { I18n } from "i18n";
import "../login/login.less";

const { useFormik } = actions


interface TPropsName {
    token: string | (string | null)[] | null
}


export const SetPassword = ({ token }: TPropsName) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const { loading } = useSelector(({ account }: RootStateOrAny) => account)
    const { newPassword, repeatPassword } = useSelector(({ formik }: RootStateOrAny) => formik.account.setPassword)
    const { onChange } = useFormik();

    const onSubmit = async () => {
        dispatch({ type: "account.loading", loading: true });
        const { data, error, message } = await setPassword(repeatPassword, token);
        if (error) Toast.error({ key: "error-reset-password", message: I18n(message, true) });
        if (data) {
            history.push("/");
            Toast.success({ key: "success-reset-password", message: I18n(message, true) });
        }

        dispatch({ type: "account.loading", loading: false });
    };

    const isDisabled = () => {
        return newPassword && repeatPassword ? false : true;
    };

    if (loading) return <Loading loading={loading} />;


    return (
        <div className="image-background">
            <div className="container-login">
                <div className="login-card">
                    <Form layout="vertical">
                        <h3>{I18n("set_password", true)}</h3>
                        <Input
                            type="password"
                            onChange={(e) => onChange(`account.setPassword.${e.target.type}`, e.target.value)}
                            externalLabel={I18n("email", true)}
                            placeholder={I18n("email", true)}
                            required={true}
                            disabled={false}
                            id="email"
                            name="email"
                            size="large"
                        />
                        <Input
                            type="password"
                            onChange={(e) => onChange(`account.setPassword.${e.target.type}`, e.target.value)}
                            externalLabel={I18n("email", true)}
                            placeholder={I18n("email", true)}
                            required={true}
                            disabled={false}
                            id="email"
                            name="email"
                            size="large"
                        />
                        <Button
                            style={{ height: 45, width: "100%" }}
                            type="primary"
                            disabled={isDisabled()}
                            onClick={() => onSubmit()}
                            htmlType="submit"
                        >
                            {I18n("send", true, true)}
                        </Button>
                    </Form>
                </div>
            </div>
        </div>
    )
}