import { FC } from "react";
import { Drawer as DrawerAntd, DrawerProps } from "antd";
import "./style.less";

interface PropsType extends DrawerProps {
  showTitle: boolean
}

export const Drawer: FC<PropsType> = (props) => {
  return (
    <DrawerAntd
      title={props.showTitle ? props.title : null}
      placement={props.placement}
      onClose={props.onClose}
      visible={props.visible}
      closeIcon={props.closeIcon}
    >
      {props.children}
    </DrawerAntd>
  );
};
