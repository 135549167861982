import { useQuery, gql } from "@apollo/client";

const GET_DATAPOINT = gql`
  query DataPoint {
    DataPoints {
      _id
      name
      customerId
      customer {
        _id
        name
      }
      longitude
      latitude
      module
      location
      active
    }
  }
`;

export function GetDataPoints() {
  const { data = {}, loading, refetch, error } = useQuery(GET_DATAPOINT);
  return { loading, data: data.DataPoints || [], refetch, error };
}
