/* eslint-disable jsx-a11y/anchor-is-valid */
import { IDataSourceSelectList } from "./interface";
import { Collapse } from "antd";
import "./style.less";

interface NameProps {
  data: IDataSourceSelectList[] | any;
  maxLength?: number,
  readMoreLabel: string
  formGroupLabel?: string;
}

const { Panel } = Collapse;
export const ListCollapse = (props: NameProps) => {

  if (!props.formGroupLabel) {
    return <ul className="rk-select-list" style={{}}>
      {props.data &&
        props.data.map((r: any, i: number) => {
          return (props.maxLength && i < props.maxLength) ?
            <li className="rk-select-list-item" style={{}}>{r.value || r.label || r.name}</li>
            : <li className="rk-select-list-item" style={{}}>{r.value || r.label || r.name}</li>
        })
      }
      {props.maxLength &&
        <div className="rk-select-list-item">
          <a>{`+${props.data.length - props.maxLength}, 
                    ${props.readMoreLabel}`}</a>
        </div>
      }
    </ul>
  }
  return (
    <>
      <label className="ant-descriptions-item-label">{props.formGroupLabel}</label>

      <Collapse className="rk-select-list" defaultActiveKey={['1']}>
        {props.data &&
          props.data.map((r: any, i: number) => {
            return (props.maxLength) ? (props.maxLength && i < props.maxLength) &&
              <Panel className="rk-select-list-item" header={r.value || r.label || r.name} key={r.value || r.label || r.name}>
                {r.content}
              </Panel>
              :
              <Panel className="rk-select-list-item" header={r.value || r.label || r.name} key={r.value || r.label || r.name}>
                {r.content}
              </Panel>
          })
        }
        {props.maxLength && props.maxLength < props.data?.length &&
          <div className="rk-select-list-item" key="1">
            {<a href="#" >{`+${props.data?.length - props.maxLength}, 
        ${props.readMoreLabel}`}</a>}
          </div>
        }
      </Collapse>

    </>
  );
};